import React from 'react';

function About() {
    return(
        <div className="p-20">
            <p>
                This is entended to be a place to share my thoughts and ideas in the hope
                that someone will find them useful. I will be wrong often, and I hope that
                I will be corrected. I do not claim to be an expert in any of the topics
                that I am going to write about.
            </p>
        </div>
    )
}

export default About;
